export const SEO = {
  title: "mattumm.al",
  url: "https://mattumm.al",
  description:
    "I'm a Cloud/Data Engineer and an entrepreneur living in the Benelux region, EU. I enjoy creating things that live on the internet, whether that be websites, applications, or anything in between.",
  siteName: "mattumm.al",
  github: "https://github.com/mattummal/portfolio",
  avatar: "https://avatars.githubusercontent.com/u/22034038",
  author: "Prabhjyoth Mattummal",
  twitter: {
    handle: "@prbhjyth",
    site: "@prbhjyth",
    cardType: "summary_large_image",
  },
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://mattumm.al",
    site_name: "mattumm.al",
    title: "Prabhjyoth Mattummal",
    description:
      "Lets make things better with quality code - Learn Web Development / API's / Automations / Serverless / Architecture / and more.",
    images: [
      {
        url: "https://mattumm.al/images/sharing-image.png",
        alt: "DevOps Engineering / Cloud Development / Data Engineering",
        width: 1200,
        height: 630,
      },
    ],
  },
};

export const BLOG_SEO = {
  title: "Prabhjyoth Mattummal » Blog",
  description:
    "Learn Web Development - TypeScript / React / APIs / Automations / Serverless / Architecture / and more. - Lets make things better with quality code",
  openGraph: {
    title: "Prabhjyoth Mattummal » Blog",
    description:
      "Learn Web Development - TypeScript / React / APIs / Automations / Serverless / Architecture / and more. - Lets make things better with quality code",
  },
};
