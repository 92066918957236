import Spotify from "components/layout/Spotify";
import { spotifyFlag } from "types/spotify";

export default function SpotifyFooter() {
  return (
    <footer className="mt-4 pb-2 justify-center">
      <main className="layout"> {spotifyFlag && <Spotify className="mt-8" />} </main>
    </footer>
  );
}
