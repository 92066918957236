import Logo from "public/logo.svg";

export const HEADER = {
  logo: {
    href: "/",
    title: <Logo />,
    alt: "Logo",
  },
  nav: [
    {
      href: "/",
      title: "Home",
      alt: "my home on the web",
      desktop: true,
    },
    {
      href: "/#about",
      title: "About",
      alt: "know more about me",
      desktop: true,
    },
    {
      href: "/#venture",
      title: "Venture",
      alt: "showcase of my work",
      desktop: true,
    },
    {
      href: "/catalogue",
      title: "Catalogue",
      alt: "a list of all my reads",
      desktop: true,
    } /*
    {
      href: "/gallery",
      title: "Gallery",
      alt: "Images and stuff",
      desktop: true,
    },*/,
    {
      href: "/resume",
      title: "Résumé",
      alt: "my professional résumé",
      desktop: true,
    },
  ],
  profileNav: [],
};
