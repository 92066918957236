export interface SpotifyData {
  isPlaying: boolean;
  title: string;
  album: string;
  artist: string;
  albumImageUrl: string;
  songUrl: string;
}

export const isProd = process.env.NODE_ENV === "production";
export const spotifyFlag = isProd || process.env.NEXT_PUBLIC_FLAG_SPOTIFY === "true";
