import clsx from "clsx";
import * as React from "react";
//import { Tooltip as TippyTooltip, TooltipProps } from "react-tippy";

export type Position =
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end"
  | "right"
  | "right-start"
  | "right-end";
export type Trigger = "mouseenter" | "focus" | "click" | "manual";
export type Animation = "shift" | "perspective" | "fade" | "scale" | "none";
export type Size = "small" | "regular" | "big";
export type Theme = "dark" | "light" | "transparent";

export interface TooltipProps {
  children?: React.ReactElement<any>;
  title?: string;
  disabled?: boolean;
  open?: boolean;
  useContext?: boolean;
  onRequestClose?: () => void;
  position?: Position;
  trigger?: Trigger;
  tabIndex?: number;
  interactive?: boolean;
  interactiveBorder?: number;
  delay?: number;
  hideDelay?: number;
  animation?: Animation;
  arrow?: boolean;
  arrowSize?: Size;
  animateFill?: boolean;
  duration?: number;
  hideDuration?: number;
  distance?: number;
  offset?: number;
  hideOnClick?: boolean | "persistent";
  multiple?: boolean;
  followCursor?: boolean;
  inertia?: boolean;
  transitionFlip?: boolean;
  popperOptions?: any;
  html?: React.ReactElement<any>;
  unmountHTMLWhenHide?: boolean;
  size?: Size;
  sticky?: boolean;
  stickyDuration?: boolean;
  beforeShown?: () => void;
  shown?: () => void;
  beforeHidden?: () => void;
  hidden?: () => void;
  theme?: Theme;
  className?: string;
  style?: React.CSSProperties;
}

export class Tooltip extends React.Component<TooltipProps> {}

export declare function withTooltip<P>(
  component: React.ComponentType<P>,
  options: TooltipProps
): JSX.Element;

export type TooltipTextProps = {
  tipChildren?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  spanClassName?: string;
  withUnderline?: boolean;
} & TooltipProps &
  Omit<React.ComponentPropsWithoutRef<"div">, "children" | "className">;

export default function Tooltipfunc({
  tipChildren,
  children,
  className,
  spanClassName,
  withUnderline = false,
  ...rest
}: TooltipTextProps) {
  return (
    <Tooltip
      trigger="mouseenter"
      interactive
      html={
        <div
          className={clsx(
            className,
            "inline-block rounded-md bg-white p-2 text-gray-600 shadow-md dark:bg-dark dark:text-gray-200",
            "border dark:border-gray-600 "
          )}
        >
          {tipChildren}
        </div>
      }
      {...rest}
    >
      {withUnderline ? (
        <span
          className={clsx(spanClassName, "underline")}
          style={{ textDecorationStyle: "dotted" }}
        >
          {children}
        </span>
      ) : (
        <>{children}</>
      )}
    </Tooltip>
  );
}
