import { SiFacebook } from "@react-icons/all-files/si/SiFacebook";
import { SiGithub } from "@react-icons/all-files/si/SiGithub";
import { SiGitlab } from "@react-icons/all-files/si/SiGitlab";
import { SiLinkedin } from "@react-icons/all-files/si/SiLinkedin";
import { SiTwitter } from "@react-icons/all-files/si/SiTwitter";

export const SOCIAL_ACCOUNTS = {
  github: {
    name: "GitHub",
    href: "https://github.com/mattummal",
    Icon: ({ className = "" }) => <SiGithub className={className} />,
  },
  gitlab: {
    name: "GitLab",
    href: "https://gitlab.com/mattummal",
    Icon: ({ className = "" }) => <SiGitlab className={className} />,
  },

  twitter: {
    name: "Twitter",
    href: "https://x.com/prbhjyth",
    Icon: ({ className = "" }) => <SiTwitter className={className} />,
  },
  facebook: {
    name: "Facebook",
    href: "https://www.facebook.com/prabhjyoth/",
    Icon: ({ className = "" }) => <SiFacebook className={className} />,
  },
  linkedin: {
    name: "LinkedIn",
    href: "https://www.linkedin.com/in/prabhjyoth",
    Icon: ({ className = "" }) => <SiLinkedin className={className} />,
  },
};
